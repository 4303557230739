
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    


  var sentryConfig = {
      DSN: 'https://e6f1a33b99834363bdfae9902de4ef66@sentry.wixpress.com/1902',
      id: 'e6f1a33b99834363bdfae9902de4ef66',
      projectName: 'crm-the-button',
      teamName: 'automations',
      
    };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/5615751eb267f18c/crm-the-button/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/5615751eb267f18c/crm-the-button/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "crm-the-button",
    biConfig: null,
    appName: "The Button",
    appDefinitionId: "84255ec5-af0e-4dbf-aad0-fe55fdbd6bcf",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: false,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: false,
          withErrorBoundary: false,
          isUsingAppSettings: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/5615751eb267f18c/crm-the-button/src/components/Button/controller.ts",
          appName: "The Button",
          appDefinitionId: "84255ec5-af0e-4dbf-aad0-fe55fdbd6bcf",
          projectName: "crm-the-button",
          componentName: "Button",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "3380ea52-6dea-449f-a22d-d520e78bf183" }],
    false);

    export const createControllers = _createControllers
